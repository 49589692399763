import React from "react";

const Footer = () => {
  return (
    <footer className="w-full bg-gray-800 text-white flex justify-center items-center absolute bottom-0 left-0 z-[1]">
      <div className="text-sm py-3">Made by ❤️ Dept. of CSE, TMSL</div>
    </footer>
  );
};

export default Footer;
